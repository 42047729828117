html {
    scroll-behavior: smooth;
}

.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.about-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px;
}

.about-text {
    max-width: 60%;
    text-align: left;
    margin-bottom: 30px;
}

.about-text h1 {
    font-size: 5em;
    font-family: 'Roboto', sans-serif;
}

.profilPic {
    width: 400px;
    height: 400px;
    border-radius: 5%;
    margin-left: 300px;
    animation: fadeInRight 1s ease-out forwards;
}

.image-and-name {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name-fade-in {
    animation: fadeInUp 1s ease-out forwards;
    font-size: 1.5em;
    font-family: 'VotrePolice', sans-serif;
    color: white;
    margin-top: 20px;
}

.drop-in {
    display: inline-block;
    animation: dropIn 0.5s ease forwards;
    opacity: 0;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes dropIn {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.arrow-container {
    display: flex;
    flex-direction: column; /* Organise les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    margin-top: 20px;
}

.arrow {
    font-size: 3em; /* Augmente la taille de la flèche */
    color: white;
    animation: pulse 1.5s infinite alternate;
    text-decoration: none;
}

.arrow-legend {
    color: white; /* Couleur du texte */
    font-size: 1em; /* Taille du texte */
}

@keyframes pulse {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.3); /* Augmente l'ampleur de l'animation */
    }
}

/* Media query pour les écrans de taille intermédiaire */
@media (min-width: 769px) and (max-width: 1510px) {
    .about-section {
        flex-direction: column; /* Change la disposition en colonne */
        margin: 50px 30px; /* Ajuste les marges */
    }

    .about-text {
        max-width: 100%; /* Utilise toute la largeur disponible */
        text-align: center; /* Centre le texte */
    }

    .about-text h1 {
        font-size: 4em; /* Ajuste la taille de la police */
    }

    .profilPic {
        width: 350px; /* Ajuste la taille de l'image */
        height: 350px;
        margin-left: 0; /* Enlève la marge à gauche */
    }

    .image-and-name {
        align-items: center; /* Centre les éléments */
    }

    .name-fade-in {
        font-size: 1.3em; /* Ajuste la taille de la police */
    }

    .arrow {
        font-size: 2.5em; /* Ajuste la taille de la flèche */
    }

    .arrow-legend {
        font-size: 1em; /* Ajuste la taille de la police */
    }
}

/* Media query pour les écrans jusqu'à 768px de large */
@media (max-width: 768px) {
    .about-section {
        flex-direction: column; /* Change la disposition en colonne */
        margin: 100px 20px; /* Réduit les marges */
    }

    .about-text {
        max-width: 100%; /* Utilise toute la largeur disponible */
        text-align: center; /* Centre le texte */
    }

    .about-text h1 {
        font-size: 3em; /* Réduit la taille de la police */
    }

    .profilPic {
        width: 300px; /* Réduit la taille de l'image */
        height: 300px;
        margin-left: 0; /* Enlève la marge à gauche */
    }

    .image-and-name {
        align-items: center; /* Centre les éléments */
    }

    .name-fade-in {
        font-size: 1.2em; /* Réduit la taille de la police */
    }

    .arrow {
        font-size: 2em; /* Réduit la taille de la flèche */
    }

    .arrow-legend {
        font-size: 0.9em; /* Réduit la taille de la police */
    }
}

