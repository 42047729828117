.portfolio-page {
    padding: 20px;
    text-align: center;
    background-color: #282c34;
}

.portfolio-title {
    margin-top: 80px;
    margin-bottom: 40px;
    color: white;
    margin-left: 100px;
    text-align: start;
}

.project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
}

.project-item {
    display: block;
    width: 350px;
    font-size: 1.2em;
    text-decoration: none;
    color: inherit;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Ajout de transition pour l'animation */
    background-color: white;
    min-height: 100%;
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.project-info {
    padding: 15px;
}

.project-title {
    margin: 0 0 10px 0;
    font-size: 1.2em;
}

.project-description {
    font-size: 0.9em;
    color: #666;
}

.project-item:hover {
    transform: scale(1.1); /* Agrandissement au survol */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Ajout d'une ombre au survol */
}
