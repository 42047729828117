/* Styles généraux */
.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.about-container > div {
    flex: 1;
    margin-left: 80px; /* Marges pour les grands écrans */
}

/* Styles pour la section de texte */
.text-section {
    text-align: left;
    animation: slide-down-fade-in 1s ease-out;
}

.text-section h2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Styles pour la section d'image */
.image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    animation: slide-up-fade-in 1s ease-out;
}

.about-container img {
    width: 40%;
    border-radius: 50%;
    border: 2px solid #000;
}

/* Styles pour l'icône de flèche */
.arrow-icon {
    font-size: 80px;
    margin-top: 10px;
    display: inline-block;
    animation: grow-shrink 2s infinite;
}

/* Styles pour les liens */
.arrow-link {
    text-decoration: none;
    color: inherit;
}

/* Animations */
@keyframes grow-shrink {
    0%, 100% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(1.5);
    }
}

@keyframes slide-down-fade-in {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-up-fade-in {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 1100px) {
    .about-container {
        flex-direction: column;
        padding: 40px;
    }

    .about-container > div,
    .text-section,
    .image-section {
        margin-left: 0;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    img {
        width: 60%; /* Augmenter la taille pour les petits écrans */
    }

    .arrow-icon {
        font-size: 60px; /* Réduire la taille de la flèche */
    }

    .text-section {
        order: 2; /* Le texte apparaît en second */
        text-align: center;
    }

    .image-section {
        order: 1; /* L'image apparaît en premier */
    }
}
