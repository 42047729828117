.navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
    color: white;
    position: fixed; /* Fixe la barre de navigation en haut */
    top: 0; /* Positionne en haut de la page */
    width: 100%; /* Largeur complète */
    z-index: 1000; /* S'assure qu'elle reste au-dessus des autres éléments */
}

.navBrand {
    font-size: 2.2em; /* Taille du texte */
    font-family: 'Montserrat', sans-serif; /* Exemple de police */
    color: white;
    margin-left: 20px;
}

.navList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end; /* Aligner les éléments à droite */
}

.navItem {
    text-decoration: none;
    color: white;
    padding: 10px 15px;
    margin: 0 10px;
    transition: color 0.3s ease;
    font-size: 1.2em; /* Augmenter la taille du texte des onglets */
}

.navItem:hover {
    color: #c745b9;
}

.navItem.contact {
    padding-right: 50px;
}

.hamburger {
    display: none; /* Cache le bouton par défaut */
    background: none; /* Enlève le fond */
    border: none; /* Enlève les bordures */
    color: white; /* Couleur des lignes (traits) */
    font-size: 2em; /* Taille des lignes */
    cursor: pointer; /* Change le curseur en pointeur */
}

/* Media query pour les petits écrans */
@media (max-width: 1100px) {

    .navBrand {
        font-size: 0.2em; /* Taille du texte */
        margin-left: 10px;
    }

    .hamburger {
        display: block; /* Affiche le bouton sur les petits écrans */
        position: fixed; /* Position fixe */
        top: 10px; /* Position verticale */
        right: 10px; /* Position horizontale, ajustée pour éviter le débordement */
        z-index: 1100; /* S'assure qu'il est au-dessus des autres éléments */
    }

    .navList {
        position: fixed; /* Positionne le menu de manière fixe */
        top: 0; /* En haut de la page */
        right: 0; /* À droite de la page */
        width: 80%; /* Largeur du menu ajustée pour éviter le débordement */
        max-width: 250px; /* Largeur maximale pour les grands téléphones */
        height: 100%; /* Hauteur complète */
        background-color: rgba(51, 51, 51, 0.9); /* Couleur semi-transparente */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateX(100%); /* Cache le menu hors de l'écran */
        transition: transform 0.3s ease; /* Animation pour faire apparaître le menu */
    }

    .navList.open {
        transform: translateX(0); /* Fait apparaître le menu */
    }

    .navItem {
        margin: 40px 0; /* Augmente la marge verticale entre les éléments */
    }

    .navBar {
        padding: 10px; /* Réduit le padding */
    }

    .navBrand {
        font-size: 1.8em; /* Réduit la taille de la police */
        margin-left: 10px; /* Réduit la marge à gauche */
    }

    .navItem.contact {
        padding-right: 10px; /* Ajuste le padding à droite */
    }

}