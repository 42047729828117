/* Styles généraux */
.skills-page {
    padding: 20px;
    background-color: #282c34;
    text-align: center;
}

/* Styles pour le titre */
.skills-title {
    margin-top: 100px;
    margin-bottom: 40px;
    margin-left: 30px;
    color: white;
    text-align: start; /* Alignement général, ajusté dans les médias */
}

/* Styles pour le conteneur de compétences */
.skills-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-category {
    margin-bottom: 30px;
    width: 100%;
}

.skill-category-title {
    color: white;
    margin-bottom: 20px;
    font-size: 1.8em; /* Taille générale, ajustée dans les médias */
}

/* Styles pour les éléments de compétence */
.skill-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    gap: 15px;
    margin-bottom: 20px;
}

.skill-item:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.skill-badge {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.skill-badge img {
    max-height: 60px;
    max-width: 100%;
    object-fit: contain;
}

.skill-name {
    color: #333;
    font-size: 1.3em;
    text-align: center;
}

/* Styles pour la phrase d'accroche */
.call-to-action {
    margin-top: 40px;
    text-align: center;
    color: white;
}

.cta-text {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.cta-link {
    color: #4CAF50;
    font-weight: bold;
    text-decoration: none;
}

.cta-link:hover {
    text-decoration: underline;
}

/* Animations */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(70px);
    }
    60% {
        opacity: 1;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Styles pour la responsivité mobile */
@media (max-width: 768px) {
    .skills-title {
        margin-top: 50px;
        margin-bottom: 20px;
        margin-left: 0;
        text-align: center;
    }

    .skill-category-title {
        font-size: 1.5em;
    }

    .skill-item {
        width: 150px;
    }

    .skill-name {
        font-size: 1em;
    }

    .call-to-action {
        margin-bottom: 40px;
    }

    .cta-link {
        padding: 10px 0;
    }
}

@keyframes color-cycle {
    0% { color: #ff0000; }
    25% { color: #00ff00; }
    50% { color: #0000ff; }
    75% { color: #ff00ff; }
    100% { color: #ff0000; }
}

.cta-link {
    /* autres styles */
    animation: color-cycle 5s linear infinite;
}
