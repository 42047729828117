.App-header .btn {
    display: inline-block;
    background-color: #c745b9;
    color: white;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    width: 200px; /* Largeur fixe */
    height: 50px; /* Hauteur fixe */
    line-height: 50px; /* Centre verticalement le texte */
    text-align: center; /* Centre horizontalement le texte */
}

.App-header ul {
    list-style-type: none; /* Enlève les puces */
    padding: 0;
}

.App-header .btn:hover {
    background-color: #a8329e; /* Nouvelle couleur au survol */
    transform: scale(1.1);
}

.fa {
    margin-right: 8px;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.App-header ul li:nth-child(odd) .btn {
    animation: fadeInRight 0.8s ease-out forwards;
}

.App-header ul li:nth-child(even) .btn {
    animation: fadeInLeft 0.8s ease-out forwards;
}
